package de.haukesomm.sokoban.core.levels

import de.haukesomm.sokoban.core.*

internal object BundledLevels {

    /**
     * Slightly adjusted character map for bundled levels since they cannot use $ to mark a box.
     * 
     * All characters except `$` are the same as in the [DefaultCharacterMap]. Boxes are marked with `X` instead.
     */
    private val bundledCharacterMap = biMapOf(
        '_' to TileProperties(TileType.Empty),
        '.' to TileProperties(TileType.Target),
        '#' to TileProperties(TileType.Wall),
        'X' to TileProperties(TileType.Empty, EntityType.Box),
        '@' to TileProperties(TileType.Empty, EntityType.Player),
        '*' to TileProperties(TileType.Target, EntityType.Box),
        '+' to TileProperties(TileType.Target, EntityType.Player)
    )

    val levelById by lazy {
        levels.associateBy(Level::id)
    }

    val levels = listOf(
        Level(
            id = "level-01",
            name = "Level 01",
            characterMap = bundledCharacterMap,
            layoutString = """
                ____#####__________
                ____#___#__________
                ____#X__#__________
                __###__X##_________
                __#__X_X_#_________
                ###_#_##_#___######
                #___#_##_#####__..#
                #_X__X__________..#
                #####_###_#@##__..#
                ____#_____#########
                ____#######________
            """.trimIndent()
        ),
        Level(
            id = "level-02",
            name = "Level 02",
            characterMap = bundledCharacterMap,
            layoutString = """
                ############__
                #..__#_____###
                #..__#_X__X__#
                #..__#X####__#
                #..____@_##__#
                #..__#_#__X_##
                ######_##X_X_#
                __#_X__X_X_X_#
                __#____#_____#
                __############
            """.trimIndent()
        ),
        Level(
            id = "level-03",
            name = "Level 03",
            characterMap = bundledCharacterMap,
            layoutString = """
                ________########_
                ________#_____@#_
                ________#_X#X_##_
                ________#_X__X#__
                ________##X_X_#__
                #########_X_#_###
                #....__##_X__X__#
                ##...____X__X___#
                #....__##########
                ########_________
            """.trimIndent()
        ),
        Level(
            id = "level-04",
            name = "Level 04",
            characterMap = bundledCharacterMap,
            layoutString = """
                ___________########
                ___________#__....#
                ############__....#
                #____#__X_X___....#
                #_XXX#X__X_#__....#
                #__X_____X_#__....#
                #_XX_#X_X_X########
                #__X_#_____#_______
                ##_#########_______
                #____#____##_______
                #_____X___##_______
                #__XX#XX__@#_______
                #____#____##_______
                ###########________
            """.trimIndent()
        ),
        Level(
            id = "level-05",
            name = "Level 05",
            characterMap = bundledCharacterMap,
            layoutString = """
                ________#####____
                ________#___#####
                ________#_#X##__#
                ________#_____X_#
                #########_###___#
                #....__##_X__X###
                #....____X_XX_##_
                #....__##X__X_@#_
                #########__X__##_
                ________#_X_X__#_
                ________###_##_#_
                __________#____#_
                __________######_
            """.trimIndent()
        ),
        Level(
            id = "level-06",
            name = "Level 06",
            characterMap = bundledCharacterMap,
            layoutString = """
                ######__###_
                #..__#_##@##
                #..__###___#
                #.._____XX_#
                #..__#_#_X_#
                #..###_#_X_#
                ####_X_#X__#
                ___#__X#_X_#
                ___#_X__X__#
                ___#__##___#
                ___#########
            """.trimIndent()
        ),
        Level(
            id = "level-07",
            name = "Level 07",
            characterMap = bundledCharacterMap,
            layoutString = """
                _______#####_
                _#######___##
                ##_#_@##_XX_#
                #____X______#
                #__X__###___#
                ###_#####X###
                #_X__###_..#_
                #_X_X_X_...#_
                #____###...#_
                #_XX_#_#...#_
                #__###_#####_
                ####_________
            """.trimIndent()
        ),
        Level(
            id = "level-08",
            name = "Level 08",
            characterMap = bundledCharacterMap,
            layoutString = """
                __####__________
                __#__###########
                __#____X___X_X_#
                __#_X#_X_#__X__#
                __#__X_X__#____#
                ###_X#_#__####_#
                #@#X_X_X__##___#
                #____X_#X#___#_#
                #___X____X_X_X_#
                #####__#########
                __#______#______
                __#______#______
                __#......#______
                __#......#______
                __#......#______
                __########______
            """.trimIndent()
        ),
        Level(
            id = "level-09",
            name = "Level 09",
            characterMap = bundledCharacterMap,
            layoutString = """
                __________#######
                __________#__...#
                ______#####__...#
                ______#______._.#
                ______#__##__...#
                ______##_##__...#
                _____###_########
                _____#_XXX_##____
                _#####__X_X_#####
                ##___#X_X___#___#
                #@_X__X____X__X_#
                ######_XX_X_#####
                _____#______#____
                _____########____
            """.trimIndent()
        ),
        Level(
            id = "level-10",
            name = "Level 10",
            characterMap = bundledCharacterMap,
            layoutString = """
                _###__#############
                ##@####_______#___#
                #_XX___XX__X_X_...#
                #__XXX#____X__#...#
                #_X___#_XX_XX_#...#
                ###___#__X____#...#
                #_____#_X_X_X_#...#
                #____######_###...#
                ##_#__#__X_X__#...#
                #__##_#_XX_X_X##..#
                #_..#_#__X______#.#
                #_..#_#_XXX_XXX_#.#
                #####_#_______#_#.#
                ____#_#########_#.#
                ____#___________#.#
                ____###############
            """.trimIndent()
        ),
        Level(
            id = "level-11",
            name = "Level 11",
            characterMap = bundledCharacterMap,
            layoutString = """
                __________####_____
                _____####_#__#_____
                ___###_@###X_#_____
                __##______X__#_____
                _##__X_XX##_##_____
                _#__#X##_____#_____
                _#_#_X_XX_#_###____
                _#___X_#__#_X_#####
                ####____#__XX_#___#
                ####_##_X_________#
                #.____###__########
                #.._..#_####_______
                #...#.#____________
                #.....#____________
                #######____________
            """.trimIndent()
        ),
        Level(
            id = "level-12",
            name = "Level 12",
            characterMap = bundledCharacterMap,
            layoutString = """
                ################_
                #______________#_
                #_#_######_____#_
                #_#__X_X_X_X#__#_
                #_#___X@X___##_##
                #_#__X_X_X###...#
                #_#___X_X__##...#
                #_###XXX_X_##...#
                #_____#_##_##...#
                #####___##_##...#
                ____#####_____###
                ________#_____#__
                ________#######__
            """.trimIndent()
        ),
        Level(
            id = "level-13",
            name = "Level 13",
            characterMap = bundledCharacterMap,
            layoutString = """
                ___#########_______
                __##___##__######__
                ###_____#__#____###
                #__X_#X_#__#__..._#
                #_#_X#@X##_#_#.#._#
                #__#_#X__#____._._#
                #_X____X_#_#_#.#._#
                #___##__##X_X_._._#
                #_X_#___#__#X#.#._#
                ##_X__X___X__X..._#
                _#X_######____##__#
                _#__#____##########
                _####______________
            """.trimIndent()
        ),
        Level(
            id = "level-14",
            name = "Level 14",
            characterMap = bundledCharacterMap,
            layoutString = """
                _______#######____
                _#######_____#____
                _#_____#_X@X_#____
                _#XX_#___#########
                _#_###......##___#
                _#___X......##_#_#
                _#_###......_____#
                ##___####_###_#X##
                #__#X___#__X__#_#_
                #__X_XXX__#_X##_#_
                #___X_X_###XX_#_#_
                #####_____X___#_#_
                ____###_###___#_#_
                ______#_____#___#_
                ______########__#_
                _____________####_
            """.trimIndent()
        ),
        Level(
            id = "level-15",
            name = "Level 15",
            characterMap = bundledCharacterMap,
            layoutString = """
                ___########______
                ___#___#__#______
                ___#__X___#______
                _###_#X___####___
                _#__X__##X___#___
                _#__#_@_X_#_X#___
                _#__#______X_####
                _##_####X##_____#
                _#_X#.....#_#___#
                _#__X..**._X#_###
                ##__#.....#___#__
                #___###_#######__
                #_XX__#__#_______
                #__#_____#_______
                ######___#_______
                _____#####_______
            """.trimIndent()
        ),
        Level(
            id = "level-16",
            name = "Level 16",
            characterMap = bundledCharacterMap,
            layoutString = """
                #####_________
                #___##________
                #____#__####__
                #_X__####__#__
                #__XX_X___X#__
                ###@_#X____##_
                _#__##__X_X_##
                _#_X__##_##_.#
                _#__#X##X__#.#
                _###___X..##.#
                __#____#.*...#
                __#_XX_#.....#
                __#__#########
                __#__#________
                __####________
            """.trimIndent()
        ),
        Level(
            id = "level-17",
            name = "Level 17",
            characterMap = bundledCharacterMap,
            layoutString = """
                ___##########___
                ___#..__#___#___
                ___#..______#___
                ___#..__#__####_
                __#######__#__##
                __#____________#
                __#__#__##__#__#
                ####_##__####_##
                #__X__#####_#__#
                #_#_X__X__#_X__#
                #_@X__X___#___##
                ####_##_#######_
                ___#____#_______
                ___######_______
            """.trimIndent()
        ),
        Level(
            id = "level-18",
            name = "Level 18",
            characterMap = bundledCharacterMap,
            layoutString = """
                _____###########___
                _____#__.__#___#___
                _____#_#.____@_#___
                _#####_##..#_####__
                ##__#_..###_____###
                #_X_#...___X_#__X_#
                #____.._##__##_##_#
                ####X##X#_X_#___#_#
                __##_#____#X_XX_#_#
                __#__X_#_#__#_X##_#
                __#_______________#
                __#__###########__#
                __####_________####
            """.trimIndent()
        ),
        Level(
            id = "level-19",
            name = "Level 19",
            characterMap = bundledCharacterMap,
            layoutString = """
                __######___________
                __#___@####________
                #####_X___#________
                #___##____####_____
                #_X_#__##____#_____
                #_X_#__#####_#_____
                ##_X__X____#_#_____
                ##_X_X_###_#_#_____
                ##_#__X__#_#_#_____
                ##_#_#X#___#_#_____
                ##_###___#_#_######
                #__X__####_#_#....#
                #____X____X___..#.#
                ####X__X#_X___....#
                #_______#__##_....#
                ###################
            """.trimIndent()
        ),
        Level(
            id = "level-20",
            name = "Level 20",
            characterMap = bundledCharacterMap,
            layoutString = """
                ____##########_____
                #####________####__
                #_____#___X__#@_#__
                #_#######X####__###
                #_#____##_#__#X_..#
                #_#_X_____#__#__#.#
                #_#_X__#_____#X_..#
                #_#__###_##_____#.#
                #_###__#__#__#X_..#
                #_#____#__####__#.#
                #_#X___X__X__#X_..#
                #____X_#_X_X_#__#.#
                ####_X###____#X_..#
                ___#____XX_###....#
                ___#______##_######
                ___########________
            """.trimIndent()
        ),
        Level(
            id = "level-21",
            name = "Level 21",
            characterMap = bundledCharacterMap,
            layoutString = """
                #########______
                #_______#______
                #_______####___
                ##_####_#__#___
                ##_#@##____#___
                #_XXX_X__XX#___
                #__#_##_X__#___
                #__#_##__X_####
                ####__XXX_X#__#
                _#___##___....#
                _#_#___#_#.._.#
                _#___#_#_##...#
                _#####_X__#...#
                _____##___#####
                ______#####____
            """.trimIndent()
        ),
        Level(
            id = "level-22",
            name = "Level 22",
            characterMap = bundledCharacterMap,
            layoutString = """
                ######_____####____
                #____#######__#####
                #___X#__#__X__#___#
                #__X__X__X_#_X_X__#
                ##X_X___#_@#_X____#
                #__X_###########_##
                #_#___#.......#_X#_
                #_##__#_......#__#_
                #_#___X........X_#_
                #_#_X_#...._..#__#_
                #__X_X####X####_X#_
                #_X___###_X___X__##
                #_X_____X_X__X____#
                ##_######_X_#####_#
                #_________#_______#
                ###################
            """.trimIndent()
        ),
        Level(
            id = "level-23",
            name = "Level 23",
            characterMap = bundledCharacterMap,
            layoutString = """
                ____#######________
                ____#__#__####_____
                #####_X#X_#__##____
                #.._#__#__#___#____
                #.._#_X#X_#__X####_
                #.__#_____#X__#__#_
                #..___X#__#_X____#_
                #..@#__#X_#X__#__#_
                #.._#_X#_____X#__#_
                #.._#__#XX#X__#__##
                #.._#_X#__#__X#X__#
                #.._#__#__#___#___#
                ##._####__#####___#
                _####__####___#####
            """.trimIndent()
        ),
        Level(
            id = "level-24",
            name = "Level 24",
            characterMap = bundledCharacterMap,
            layoutString = """
                ###############____
                #..........__.####_
                #..........XX.#__#_
                ###########X_#___##
                #______X__X_____X_#
                ##_####___#__X_#__#
                #______#___##__#_##
                #__X#__#_##__###_##
                #_X_#X###____###_##
                ###__X_#__#__###_##
                ###____X_##_#__#_##
                _#_X__#__X__X_X___#
                _#__X__X#XXX__#___#
                _#__#__X______#####
                _#_@##__#__#__#____
                _##############____
            """.trimIndent()
        ),
        Level(
            id = "level-25",
            name = "Level 25",
            characterMap = bundledCharacterMap,
            layoutString = """
                ####_______________
                #__##############__
                #__#___..#......#__
                #__#_#_#####_...#__
                ##X#____........#__
                #___##X######__####
                #_X_#_____######@_#
                ##X_#_X___######__#
                #__X_#XXX##_______#
                #______#____#X#X###
                #_####_#XXXXX____#_
                #_#____X_____#___#_
                #_#___##________###
                #_######X######_X_#
                #________#____#___#
                ##########____#####
            """.trimIndent()
        ),
        Level(
            id = "level-26",
            name = "Level 26",
            characterMap = bundledCharacterMap,
            layoutString = """
                _#######_______
                _#__#__#####___
                ##__#__#...###_
                #__X#__#...__#_
                #_X_#XX_...__#_
                #__X#__#..._.#_
                #___#_X########
                ##X_______X_X_#
                ##__#__XX_#___#
                _######__##XX@#
                ______#______##
                ______########_
            """.trimIndent()
        ),
        Level(
            id = "level-27",
            name = "Level 27",
            characterMap = bundledCharacterMap,
            layoutString = """
                _#################_
                _#...___#____#___##
                ##.....__X##_#_#X_#
                #......#__X__#____#
                #......#__#__#_#__#
                #########_X__X_X__#
                __#_____#X##X_##X##
                _##___X____#_X____#
                _#__##_###_#__##X_#
                _#_X_XX_____X__X__#
                _#_X____X##X_######
                _#######__@_##_____
                _______######______
            """.trimIndent()
        ),
        Level(
            id = "level-28",
            name = "Level 28",
            characterMap = bundledCharacterMap,
            layoutString = """
                _________#####___
                _____#####___#___
                ____##_X__X__####
                #####_X__X_X_##.#
                #_______XX__##..#
                #__######_###.._#
                ##_#__#____#..._#
                #_X___#____#..._#
                #@_#X_##_####...#
                ####__X_XX__##..#
                ___##__X_X__X...#
                ____#_XX__X_#__.#
                ____#___X_X__####
                ____######___#___
                _________#####___
            """.trimIndent()
        ),
        Level(
            id = "level-29",
            name = "Level 29",
            characterMap = bundledCharacterMap,
            layoutString = """
                #####______________
                #___##_____________
                #_X__#########_____
                ##_#_#_______######
                ##_#___X#X#@__#___#
                #__#______X_#___X_#
                #__###_#########_##
                #__##_..*....._#_##
                ##_##_*.*..*.*_#_##
                #_X##########_##X_#
                #__X___X__X____X__#
                #__#___#___#___#__#
                ###################
            """.trimIndent()
        ),
        Level(
            id = "level-30",
            name = "Level 30",
            characterMap = bundledCharacterMap,
            layoutString = """
                _______###########_
                _______#___#_____#_
                #####__#_____X_X_#_
                #___#####_X##_#_##_
                #_X_##___#_##_X__#_
                #_X__@XX_#_##XXX_#_
                ##_###___#_##____#_
                ##_#___###_#####X#_
                ##_#_____X__#....#_
                #__###_##_X_#....##
                #_X___X_#___#..X._#
                #__##_X_#__##...._#
                #####___######...##
                ____#####____#####_
            """.trimIndent()
        ),
        Level(
            id = "level-31",
            name = "Level 31",
            characterMap = bundledCharacterMap,
            layoutString = """
                __####____________
                __#__#########____
                _##__##__#___#____
                _#__X#_X@X___####_
                _#X__X__#_X_X#__##
                ##__X##_#X_X_____#
                #__#__#_#___XXX__#
                #_X____X__X##_####
                #_X_X_#X#__#__#___
                ##__###__###X_#___
                _#__#...._____#___
                _####......####___
                ___#....####______
                ___#...##_________
                ___#...#__________
                ___#####__________
            """.trimIndent()
        ),
        Level(
            id = "level-32",
            name = "Level 32",
            characterMap = bundledCharacterMap,
            layoutString = """
                ______####___
                __#####__#___
                _##_____X#___
                ##_X__##_###_
                #@X_X_#_X__#_
                ####_##___X#_
                _#....#X_X_#_
                _#....#___X#_
                _#....__XX_##
                _#..._#_X___#
                _######X_X__#
                ______#___###
                ______#X_###_
                ______#__#___
                ______####___
            """.trimIndent()
        ),
        Level(
            id = "level-33",
            name = "Level 33",
            characterMap = bundledCharacterMap,
            layoutString = """
                ############
                ##_____##__#
                ##___X___X_#
                ####_##_XX_#
                #___X_#____#
                #_XXX_#_####
                #___#_#_X_##
                #__#__#__X_#
                #_X#_X#____#
                #___..#_####
                ####.._X_#@#
                #.....#_X#_#
                ##....#__X_#
                ###..##____#
                ############
            """.trimIndent()
        ),
        Level(
            id = "level-34",
            name = "Level 34",
            characterMap = bundledCharacterMap,
            layoutString = """
                _#########____
                _#....___##___
                _#.#.#__X_##__
                ##....#_#_@##_
                #_....#__#__##
                #_____#X_##X_#
                ##_###__X____#
                _#X__X_X_X#__#
                _#_#__X_X_##_#
                _#__###__##__#
                _#____##_##_##
                _#__X_#__X__#_
                _###X_X___###_
                ___#__#####___
                ___####_______
            """.trimIndent()
        ),
        Level(
            id = "level-35",
            name = "Level 35",
            characterMap = bundledCharacterMap,
            layoutString = """
                ############_######
                #___#____#_###....#
                #___XX#___@__.....#
                #___#_###___#_....#
                ##_##_###__#__....#
                _#_X_X_____#_#_####
                _#__X_X##__#______#
                ####_#__####_#_##_#
                #__#_#X___##_#____#
                #_X__X__#_##_#___##
                #_#_X_X____#_#___#_
                #__X_##_##_#_#####_
                #_XX_____XX__#_____
                ##_##_###_X__#_____
                _#____#_#____#_____
                _######_######_____
            """.trimIndent()
        ),
        Level(
            id = "level-36",
            name = "Level 36",
            characterMap = bundledCharacterMap,
            layoutString = """
                ____________#####__
                #####__######___#__
                #___####__X_X_X_#__
                #_X___##_##_##__##_
                #___X_X_____X__X_#_
                ###_X__##_##_____##
                __#_#####_#####XX_#
                _##X#####_@##_____#
                _#_X__###X###_X__##
                _#_X__#___###__###_
                _#_XX_X_#___XX_#___
                _#_____#___##__#___
                _#######.._.###____
                ____#.........#____
                ____#.........#____
                ____###########____
            """.trimIndent()
        ),
        Level(
            id = "level-37",
            name = "Level 37",
            characterMap = bundledCharacterMap,
            layoutString = """
                ###########________
                #......___#########
                #......___#__##___#
                #..###_X____X_____#
                #..._X_X_#___##___#
                #...#X#####____#__#
                ###____#___#X__#X_#
                __#__XX_X_X__X##__#
                __#__X___#X#X_##X_#
                __###_##_#____##__#
                ___#__X_X_##_######
                ___#____X__X__#____
                ___##___#_#___#____
                ____#####@#####____
                ________###________
            """.trimIndent()
        ),
        Level(
            id = "level-38",
            name = "Level 38",
            characterMap = bundledCharacterMap,
            layoutString = """
                ______####_
                #######_@#_
                #_____X__#_
                #___X##_X#_
                ##X#...#_#_
                _#_X...__#_
                _#_#._.#_##
                _#___#_#X_#
                _#X__X____#
                _#__#######
                _####______
            """.trimIndent()
        ),
        Level(
            id = "level-39",
            name = "Level 39",
            characterMap = bundledCharacterMap,
            layoutString = """
                _____________######
                _#############....#
                ##___##_____##....#
                #__XX##__X_@##....#
                #______XX_X#__....#
                #__X_##_XX_#_#_...#
                #__X_##_X__#__....#
                ##_#####_###_##.###
                ##___X__X_##___.__#
                #_X###__#_#####_###
                #___X___#_______#__
                #__X_#X_X_X###__#__
                #_XXX#_X___#_####__
                #____#__XX_#_______
                ######___###_______
                _____#####_________
            """.trimIndent()
        ),
        Level(
            id = "level-40",
            name = "Level 40",
            characterMap = bundledCharacterMap,
            layoutString = """
                ____############_
                ____#__________##
                ____#__#_#XX_X__#
                ____#X_#X#__##_@#
                ___##_##_#_X_#_##
                ___#___X_#X__#_#_
                ___#___#_X___#_#_
                ___##_X_X___##_#_
                ___#__#__##__X_#_
                ___#____##_XX#_#_
                ######XX___#___#_
                #....#__########_
                #.#..._##________
                #....___#________
                #....___#________
                #########________
            """.trimIndent()
        ),
        Level(
            id = "level-41",
            name = "Level 41",
            characterMap = bundledCharacterMap,
            layoutString = """
                ___________#####___
                __________##___##__
                _________##_____#__
                ________##__XX__#__
                _______##_XX__X_#__
                _______#_X____X_#__
                ####___#___XX_#####
                #__########_##____#
                #.____________XXX@#
                #.#_#######_##___##
                #.#_#######._#X_X##
                #..........._#____#
                ##############__X_#
                _____________##__##
                ______________####_
            """.trimIndent()
        ),
        Level(
            id = "level-42",
            name = "Level 42",
            characterMap = bundledCharacterMap,
            layoutString = """
                _____########_____
                __####______######
                __#____##_X_X___@#
                __#_##_##X#X_X_X##
                ###_......#__XX_##
                #___......#__#___#
                #_#_......#X__X__#
                #_#X......_XX#_X_#
                #___###_###X__X_##
                ###__X__X__X__X_#_
                __#__X__X__X__X_#_
                __######___######_
                _______#####______
            """.trimIndent()
        ),
        Level(
            id = "level-43",
            name = "Level 43",
            characterMap = bundledCharacterMap,
            layoutString = """
                ________#######____
                ____#####__#__####_
                ____#___#___X____#_
                _####_#XX_##_##__#_
                ##______#_#__##_###
                #__###_X#X__X__X__#
                #...____#_##__#___#
                #...#____@_#_###_##
                #...#__###__X__X__#
                ########_##___#___#
                __________#########
            """.trimIndent()
        ),
        Level(
            id = "level-44",
            name = "Level 44",
            characterMap = bundledCharacterMap,
            layoutString = """
                _#####_____________
                _#___#_____________
                _#_#_#######_______
                _#______X@######___
                _#_X_##X_###___#___
                _#_####_X____X_#___
                _#_#####_#__#X_####
                ##__####_##X______#
                #__X#__X__#_##_##_#
                #_________#_#...#_#
                ######__###__...__#
                _____####_#_#...#_#
                __________#_###_#_#
                __________#_______#
                __________#########
            """.trimIndent()
        ),
        Level(
            id = "level-45",
            name = "Level 45",
            characterMap = bundledCharacterMap,
            layoutString = """
                #####_####______
                #...#_#__####___
                #...###__X__#___
                #....##_X__X###_
                ##....##___X__#_
                ###..._##_X_X_#_
                #_##____#__X__#_
                #__##_#_###_####
                #_X_#_#X__X____#
                #__X_@_X____X__#
                #___#_X_XX_X_###
                #__######__###__
                #_##____####____
                ###_____________
            """.trimIndent()
        ),
        Level(
            id = "level-46",
            name = "Level 46",
            characterMap = bundledCharacterMap,
            layoutString = """
                ##########____
                #________####_
                #_######_#__##
                #_#_X_X_X__X_#
                #_______#X___#
                ###X__XX#__###
                __#__##_#_X##_
                __##X#___X_@#_
                ___#__X_X_###_
                ___#_#___X__#_
                ___#_##___#_#_
                __##__#####_#_
                __#_________#_
                __#.......###_
                __#.......#___
                __#########___
            """.trimIndent()
        ),
        Level(
            id = "level-47",
            name = "Level 47",
            characterMap = bundledCharacterMap,
            layoutString = """
                _________####_____
                _#########__##____
                ##__X______X_#####
                #___##_##___##...#
                #_#XX_X_XX#X##...#
                #_#___@___#___...#
                #__X#_###XX___...#
                #_X__XX__X_##....#
                ###X_______#######
                __#__#######______
                __####____________
            """.trimIndent()
        ),
        Level(
            id = "level-48",
            name = "Level 48",
            characterMap = bundledCharacterMap,
            layoutString = """
                __#########__
                __#*.*#*.*#__
                __#.*.*.*.#__
                __#*.*.*.*#__
                __#.*.*.*.#__
                __#*.*.*.*#__
                __###___###__
                ____#___#____
                ######_######
                #___________#
                #_X_X_X_X_X_#
                ##_X_X_X_X_##
                _#X_X_X_X_X#_
                _#___X@X___#_
                _#__#####__#_
                _####___####_
            """.trimIndent()
        ),
        Level(
            id = "level-49",
            name = "Level 49",
            characterMap = bundledCharacterMap,
            layoutString = """
                _______####_____
                _______#__##____
                _______#___##___
                _______#_XX_##__
                _____###X__X_##_
                __####____X___#_
                ###__#_#####__#_
                #____#_#....X_#_
                #_#___X_....#_#_
                #__X_#_#.*..#_#_
                ###__####_###_#_
                __####_@X__##X##
                _____###_X_____#
                _______#__##___#
                _______#########
            """.trimIndent()
        ),
        Level(
            id = "level-50",
            name = "Level 50",
            characterMap = bundledCharacterMap,
            layoutString = """
                ______############_
                _____##..____#___#_
                ____##..*_X____X_#_
                ___##..*.#_#_#_X##_
                ___#..*.#_#_#_X__#_
                ####...#__#____#_#_
                #__##_#__________#_
                #_@X_X_###__#___##_
                #_X___X___#_#___#__
                ###XX___#_#_#_#_#__
                __#___X___#_#_#####
                __#_X#_#####______#
                __#X___#___#____#_#
                __#__###___##_____#
                __#__#______#____##
                __####______######_
            """.trimIndent()
        ),
    )
}